<template>
  <div class="add-dept">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="80px"
      class="demo-ruleForm"
    >
      <el-form-item label="编号：" prop="number">
        <el-input
          v-model="ruleForm.number"
          placeholder="请输入名称"
          @input="handleOnKeyup('number')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="`${this.paramsObj.parentId ? '地块' : '园区'}：`"
        prop="label"
      >
        <el-input
          v-model="ruleForm.label"
          placeholder="请输入名称"
          @input="handleOnKeyup('label')"
        ></el-input>
      </el-form-item>
      <div class="btn-boxs">
        <el-button class="btns" type="primary" @click="submitForm('ruleForm')"
          >确定</el-button
        >
        <el-button @click="handleClose">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "AddDept",
  props: {
    handleClose: Function,
    getDeptUpdate: Function,
    paramsObj: Object,
  },
  data() {
    return {
      ruleForm: JSON.parse(JSON.stringify(this.paramsObj)),
      rules: {
        label: [{ required: true, message: "请输入园区", trigger: "blur" }],
        number: [{ required: true, message: "请输入编号", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleOnKeyup(type) {
      console.log(type);
      this.ruleForm[type] = this.handleKeyup(this.ruleForm[type]);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getDeptUpdate({
            id: this.ruleForm.id,
            name: this.ruleForm.label,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
